import React from 'react';
import styles from './RecommendedMaterial.module.css'; // Import the CSS module

const RecommendedMaterial = () => {
  return (
    <div className={styles.box}>
      <h2 className={styles.heading}>Recommended Material</h2>
      <div className={styles.content}>
        <div className={styles.video}>
          <a href="https://open.spotify.com/show/7iQXmUT7XGuZSzAMjoNWlX?si=c524e021f5504083&nd=1&dlsi=1a4382cef2c44ba9" target="_blank" rel="noopener noreferrer">
            <img src="https://m.media-amazon.com/images/I/41NlQGjBq3L._SL500_.jpg" alt="The Diary Of A CEO with Steven Bartlett" className={styles.thumbnail} />
          </a>
          <p className={styles.videoTitle}>The Diary Of A CEO with Steven Bartlett</p>
        </div>
        <div className={styles.video}>
          <a href="https://open.spotify.com/show/3WKI7p6tuq6kJRw1xfRS7Q?si=078c630ab5614077&nd=1&dlsi=a657a14658bc4a19" target="_blank" rel="noopener noreferrer">
            <img src="https://www.sportspodcastgroup.com/wp-content/uploads/2023/01/High-Perfomance-Podcast-Art-2022.jpeg" alt="The High Performance Podcast" className={styles.thumbnail} />
          </a>
          <p className={styles.videoTitle}>The High Performance Podcast</p>
        </div>
        <div className={styles.video}>
          <a href="https://www.youtube.com/watch?v=amdXa3CfzHw&t=1406s" target="_blank" rel="noopener noreferrer">
            <img src="https://img.youtube.com/vi/amdXa3CfzHw/0.jpg" alt="10 Steps That’ll Turn You into A Sales Machine" className={styles.thumbnail} />
          </a>
          <p className={styles.videoTitle}>10 Steps That’ll Turn You into A Sales Machine</p>
        </div>
        <div className={styles.video}>
          <a href="https://www.youtube.com/watch?v=u_ktRTWMX3M" target="_blank" rel="noopener noreferrer">
            <img src="https://img.youtube.com/vi/u_ktRTWMX3M/0.jpg" alt="Arnold Schwarzenegger 2018 - The speech that broke the internet - Most Inspiring ever" className={styles.thumbnail} />
          </a>
          <p className={styles.videoTitle}>The speech that broke the internet - Most Inspiring ever</p>
        </div>
        <div className={styles.video}>
          <a href="https://www.youtube.com/shorts/c3DiD7D4yJI" target="_blank" rel="noopener noreferrer">
            <img src="https://img.youtube.com/vi/c3DiD7D4yJI/0.jpg" alt="Why Rocky is the Most Motivational Movie EVER!" className={styles.thumbnail} />
          </a>
          <p className={styles.videoTitle}>Why Rocky is the Most Motivational Movie EVER!</p>
        </div>
        <div className={styles.video}>
          <a href="https://www.netflix.com/gb/title/80203144?s=i&trkid=258593161&vlang=en" target="_blank" rel="noopener noreferrer">
            <img src="https://image-cdn-ak.spotifycdn.com/image/ab67706c0000da847391853baf1e1dea0658ddc7" alt="Why Rocky is the Most Motivational Movie EVER!" className={styles.thumbnail} />
          </a>
          <p className={styles.videoTitle}>The Last Dance</p>
        </div>
        <div className={styles.video}>
          <a href="https://apps.apple.com/app/id1144352844" target="_blank" rel="noopener noreferrer">
            <img src="https://is1-ssl.mzstatic.com/image/thumb/Purple211/v4/74/b0/80/74b08031-5f68-a55e-95a8-e58fc4d20d12/AppIcon-0-0-1x_U007epad-0-85-220.png/434x0w.webp" alt="Why Rocky is the Most Motivational Movie EVER!" className={styles.thumbnail} />
          </a>
          <p className={styles.videoTitle}>Counter Tally Count</p>
        </div>
      </div>
    </div>
  );
};

export default RecommendedMaterial;
